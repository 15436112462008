import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Row, Select, Space, Table, Tooltip } from "antd";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { DisplayContext } from "../Context/displayContext";

export default function ClientReports() {
  const displayContext = useContext(DisplayContext);
  const [displayTimezone, setDisplayTimezone] = useState<string>();
  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
  }, [displayContext, displayTimezone]);
  const [yearLoaded, setYearLoaded] = useState<boolean>(false);
  const [yearOptions, setYearOptions] = useState<any[]>([]);
  const [selectedYear, setSelectedYear] = useState();
  const [isFetchingFiles, setIsFetchingFiles] = useState<boolean>(false);
  const [s3Files, setS3Files] = useState<any>([]);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isAnyDataFetched, setIsAnyDataFetched] = useState<boolean>(false);
  const fetchYearOptions = useCallback(() => {
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/operations/report/client/year-list",
      withCredentials: true,
    })
      .then((res) => {
        setYearOptions(
          res.data.map((row: any) => ({
            label: row,
            value: row,
            key: row,
          })),
        );
      })
      .finally(() => {
        setYearLoaded(true);
      });
  }, []);
  useEffect(() => {
    fetchYearOptions();
  }, [fetchYearOptions]);
  const fetchFiles = useCallback(() => {
    setIsFetchingFiles(true);
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/operations/report/client",
      withCredentials: true,
      params: {
        year: selectedYear,
      },
    })
      .then((res) => {
        const data = res.data.map((row: any) => ({
          label: row.Key?.split("/")?.pop(),
          value: row.Key,
          LastModified: row.LastModified || "",
          key: row.Key,
        }));
        setS3Files(data);
      })
      .finally(() => {
        setIsAnyDataFetched(true);
        setIsFetchingFiles(false);
      });
  }, [selectedYear]);
  const downloadFile = useCallback((fileName: string) => {
    if (!fileName) {
      toast.error("Something is wrong");
      return;
    }
    setIsDownloading(true);
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/operations/report/client/download",
      withCredentials: true,
      params: {
        fileName,
      },
    })
      .then((res) => {
        const url = res.data?.url;
        window.open(url, "_blank");
      })
      .catch((err) => {
        toast.error("Something is wrong");
      })
      .finally(() => {
        setIsDownloading(false);
      });
  }, []);

  return (
    <Content id="client-reports">
      <Row align={"middle"} style={{ margin: "1em" }}>
        <label style={{ marginRight: 8 }}>
          <b>Year:</b>
        </label>
        <Select
          loading={!yearLoaded}
          style={{ width: 120 }}
          placeholder="Select Year"
          value={selectedYear}
          onChange={(e) => setSelectedYear(e)}
          options={yearOptions}
        />
        <Button
          onClick={() => fetchFiles()}
          loading={isFetchingFiles}
          disabled={!yearLoaded || !selectedYear}
        >
          Get Files
        </Button>
      </Row>
      {isAnyDataFetched ? (
        <Row justify={"center"}>
          <Table
            dataSource={s3Files}
            loading={isFetchingFiles}
            columns={[
              {
                title: "label",
                dataIndex: "label",
                sorter: (a: any, b: any) => a.label.localeCompare(b.label),
              },
              {
                title: "Last Modified",
                dataIndex: "LastModified",
                sorter: (a, b) =>
                  new Date(a.LastModified).getTime() -
                  new Date(b.LastModified).getTime(),
                render: (_: any, { LastModified }: any) => (
                  <Space>
                    {new Date(LastModified).toLocaleString("en-US", {
                      timeZone: displayContext?.displayContext.timezone,
                      timeZoneName: "short",
                    })}
                  </Space>
                ),
              },
              {
                title: "Actions",
                dataIndex: "actions",
                render: (_: any, { value }: any) => (
                  <Row justify={"space-evenly"}>
                    <Tooltip title="Download">
                      <Button
                        style={{
                          border: "none",
                          background: "none",
                          boxShadow: "none",
                        }}
                        loading={isDownloading}
                        onClick={() => {
                          downloadFile(value);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={"fa-solid fa-download" as IconProp}
                        />
                      </Button>
                    </Tooltip>
                  </Row>
                ),
              },
            ]}
            sticky
          />
        </Row>
      ) : undefined}
    </Content>
  );
}
