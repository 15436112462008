import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Layout,
  Pagination,
  Row,
  Select,
  Space,
  Spin,
  Table,
  TableColumnsType,
  Tooltip,
} from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useCopyToClipboard from "../../../hooks/useCopyToClipboard";
import { DisplayContext } from "../../Context/displayContext";
dayjs.extend(utc);
dayjs.extend(timezone);

const { RangePicker } = DatePicker;

interface ExpandedDataType {
  assetId: React.Key;
  closingDate: string;
  closingPrice: string;
}

export default function ViewAssetPriceList({ canExport = true }) {
  const [, copy] = useCopyToClipboard();
  const displayContext = useContext(DisplayContext);
  const [filterForm] = Form.useForm();
  const [showFilter, setShowFilter] = useState<boolean>(true);
  const [assetCount, setAssetCount] = useState<number>();
  const [currentPage, setCurrentPage] = useState(0);
  const [currPageSize, setCurrPageSize] = useState(10);
  const [onFinshiLoad, setOnFinishLoad] = useState<boolean>(false);
  const [assetPriceList, setAssetPriceList] = useState<any[]>();
  const [assetOptions, setAssetOptions] = useState<any[]>();
  const [displayTimezone, setDisplayTimezone] = useState<string>();
  const navigate = useNavigate();
  // const [displayColumns, setDisplayColumns] = useState<any[]>([]);

  const copyCSV = useCallback(() => {
    axios({
      method: "POST",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/prices/export-csv",
      data: {
        copyToClipboard: true,
        startDate: filterForm.getFieldValue("dateRange")
          ? dayjs(filterForm.getFieldValue("dateRange")[0].format("YYYYMMDD"))
              .tz("America/New_York", true)
              .startOf("day")
              .format()
          : undefined,
        endDate: filterForm.getFieldValue("dateRange")
          ? dayjs(filterForm.getFieldValue("dateRange")[1].format("YYYYMMDD"))
              .tz("America/New_York", true)
              .endOf("day")
              .format()
          : undefined,
        assetNameIds: filterForm.getFieldValue("assetNames")
          ? filterForm.getFieldValue("assetNames")
          : undefined,
      },
      withCredentials: true,
    })
      .then((res) => {
        copy(res.data);
        toast.success("Data copied to clipboard", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
          return;
        }
      });
  }, [copy, filterForm, navigate]);
  const expandedRowRender = (row: any) => {
    const columns: TableColumnsType<ExpandedDataType> = [
      {
        title: "Date",
        dataIndex: "closingDate",
        key: "closingDate",
        render: (_: any, { closingDate }: any) =>
          closingDate ? (
            <Space>
              {new Date(closingDate)
                .toLocaleString("en-US", {
                  timeZone: displayTimezone,
                  timeZoneName: "short",
                })
                .split(", ")
                .join(" ")}
            </Space>
          ) : (
            <></>
          ),
      },
      {
        title: "Price",
        dataIndex: "closingPrice",
        key: "closingPrice",
        render: (_: any, { closingPrice }: any) =>
          closingPrice ? (
            <Space>
              $
              {Intl.NumberFormat("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }).format(closingPrice)}
            </Space>
          ) : (
            <></>
          ),
      },
    ];
    return (
      <Table
        columns={columns}
        dataSource={row.assetPrices}
        pagination={false}
      />
    );
  };

  const initialColumns = [
    {
      title: "Asset Name",
      dataIndex: "asset_name",
      hidden: false,
      sorter: false,
      editable: false,
    },
    {
      title: "Asset Ticker",
      dataIndex: "ticker",
      hidden: false,
      sorter: false,
      editable: false,
    },
    {
      title: "Latest Closing Date",
      dataIndex: "last_price_date",
      hidden: false,
      sorter: false,
      editable: false,
      render: (_: any, { last_price_date }: any) =>
        last_price_date ? (
          <Space>
            {new Date(last_price_date)
              .toLocaleString("en-US", {
                timeZone: displayTimezone,
                timeZoneName: "short",
              })
              .split(", ")
              .join(" ")}
          </Space>
        ) : (
          <></>
        ),
    },
    {
      title: "Latest Closing Price",
      dataIndex: "last_price",
      hidden: false,
      sorter: false,
      editable: false,
      render: (_: any, { last_price }: any) =>
        last_price ? (
          <Tooltip
            title={`$ ${Intl.NumberFormat("en-US", {
              maximumFractionDigits: 14,
              minimumFractionDigits: 2,
            }).format(last_price)}`}
          >
            $
            {Intl.NumberFormat("en-US", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }).format(last_price)}
          </Tooltip>
        ) : (
          <></>
        ),
    },
  ];

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days after today and today
    return current > dayjs().endOf("day");
  };

  const getAssetList = useCallback((params = {}) => {
    setOnFinishLoad(false);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/select-list",
      withCredentials: true,
    })
      .then((res) => {
        const options = [];
        for (const asset of res.data.assetList) {
          options.push({
            label: `${asset.name} (${asset.ticker})`,
            value: asset.id,
          });
        }
        options.sort((a, b) => a.label.localeCompare(b.label));
        setAssetOptions(options);
      })
      .finally(() => {
        setOnFinishLoad(true);
      });
  }, []);

  const fetchData = useCallback(
    (
      params = {
        offset: 0,
        pageSize: 10,
        startDate: undefined,
        endDate: undefined,
      },
    ) => {
      setOnFinishLoad(false);
      axios({
        method: "GET",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/prices",
        params: {
          offset: params.offset,
          pageSize: params.pageSize,
          startDate: params.startDate,
          endDate: params.endDate,
        },
        withCredentials: true,
      })
        .then((res) => {
          setCurrPageSize(params.pageSize);
          if (params.offset === 0) setAssetCount(res.data.assetCount);
          for (const row of res.data.priceData) {
            row.key = row.id;
          }
          setAssetPriceList(res.data.priceData);
        })
        .finally(() => {
          setOnFinishLoad(true);
        });
    },
    [],
  );

  const filterAssetPrice = (form: any) => {
    let startDate, endDate;
    setOnFinishLoad(false);
    if (form.dateRange) {
      startDate = dayjs(form.dateRange[0].format("YYYYMMDD"))
        .tz("America/New_York", true)
        .startOf("day")
        .format();
      endDate = dayjs(form.dateRange[1].format("YYYYMMDD"))
        .tz("America/New_York", true)
        .endOf("day")
        .format();
    }
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/prices",
      params: {
        offset: 0,
        pageSize: currPageSize,
        startDate: form.dateRange ? startDate : undefined,
        endDate: form.dateRange ? endDate : undefined,
        assetNames:
          form.assetNames && form.assetNames.length
            ? JSON.stringify(form.assetNames)
            : undefined,
      },
      withCredentials: true,
    })
      .then((res) => {
        for (const row of res.data.priceData) {
          row.key = row.id;
        }
        setAssetCount(res.data.assetCount);
        setAssetPriceList(res.data.priceData);
        setCurrentPage(1);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      })
      .finally(() => {
        setOnFinishLoad(true);
      });
  };

  const downloadCSV = () => {
    axios({
      method: "POST",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/prices/export-csv",
      data: {
        startDate: filterForm.getFieldValue("dateRange")
          ? dayjs(filterForm.getFieldValue("dateRange")[0].format("YYYYMMDD"))
              .tz("America/New_York", true)
              .startOf("day")
              .format()
          : undefined,
        endDate: filterForm.getFieldValue("dateRange")
          ? dayjs(filterForm.getFieldValue("dateRange")[1].format("YYYYMMDD"))
              .tz("America/New_York", true)
              .endOf("day")
              .format()
          : undefined,
        assetNameIds: filterForm.getFieldValue("assetNames")
          ? filterForm.getFieldValue("assetNames")
          : undefined,
      },
      withCredentials: true,
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let date = new Date();

        link.setAttribute(
          "download",
          `${date.getFullYear()}${String(date.getMonth() + 1).padStart(
            2,
            "0",
          )}${String(date.getDate()).padStart(2, "0")}_asset_price.csv`,
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
  };

  const onPaginationChange = (current: any, pageSize: any) => {
    const offset =
      current === 1 || current === 0 ? 0 : (current - 1) * pageSize;
    fetchData({
      offset: offset,
      pageSize: pageSize,
      startDate: filterForm.getFieldValue("dateRange")
        ? filterForm
            .getFieldValue("dateRange")[0]
            .tz("America/New_York", true)
            .startOf("day")
            .format()
        : undefined,
      endDate: filterForm.getFieldValue("dateRange")
        ? filterForm
            .getFieldValue("dateRange")[1]
            .tz("America/New_York", true)
            .endOf("day")
            .format()
        : undefined,
    });
    setCurrPageSize(pageSize);
    setCurrentPage(current);
  };

  const onReset = () => {
    fetchData();
    filterForm.resetFields();
  };

  useEffect(() => {
    fetchData({
      offset: 0,
      pageSize: 10,
      startDate: undefined,
      endDate: undefined,
    });
    getAssetList();
  }, [fetchData, getAssetList]);

  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
  }, [displayContext, displayTimezone]);
  return onFinshiLoad ? (
    <Content id="view-asset-price">
      <Row className="dcl-filter-row">
        <Col md={{ span: 16 }} sm={{ span: 16 }}>
          <Space>
            <Button
              className="dcl-btn-toggle"
              style={{ marginRight: "10px" }}
              onClick={() => {
                setShowFilter(!showFilter);
              }}
            >
              <FontAwesomeIcon icon={"fa-solid fa-filter" as IconProp} />
              Filters
              {showFilter ? (
                <FontAwesomeIcon icon={"fa-solid fa-caret-up" as IconProp} />
              ) : (
                <FontAwesomeIcon icon={"fa-solid fa-caret-down" as IconProp} />
              )}
            </Button>
          </Space>
          <Form
            title="Filter"
            form={filterForm}
            onFinish={filterAssetPrice}
            className="dcl-toggled-content dcl-toggled-content-filter"
            hidden={!showFilter}
            labelWrap
          >
            <Row>
              <Form.Item name="dateRange" className="dcl-filter-item">
                <RangePicker
                  disabledDate={disabledDate}
                  className="dcl-daterange-select dcl-daterange-select"
                />
              </Form.Item>
              <Form.Item name="assetNames" className="dcl-filter-item">
                <Select
                  mode="multiple"
                  allowClear
                  className="dcl-asset-select"
                  placeholder="Asset Ticker"
                  options={assetOptions}
                  popupMatchSelectWidth={false}
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
            </Row>
            <Row justify="end">
              <Space>
                {assetCount}
                <b>Results</b>
              </Space>
              <Space>
                <Button htmlType="submit" className="ant-btn-primary">
                  Apply
                </Button>
                <Button onClick={onReset}>Reset</Button>
              </Space>
            </Row>
          </Form>
        </Col>
        {canExport ? (
          <Col md={{ span: 8, order: 1 }} sm={{ span: 8, order: 1 }}>
            <Row justify="end">
              <Button
                className="dcl-btn-toggle"
                style={{ marginRight: "10px" }}
                onClick={copyCSV}
              >
                <FontAwesomeIcon icon={"fa-regular fa-copy" as IconProp} />
                Copy to Clipboard
              </Button>
              <Button
                className="dcl-btn-toggle"
                style={{ marginRight: "10px" }}
                // disabled={true}
                onClick={downloadCSV}
              >
                <FontAwesomeIcon icon={"fa-solid fa-file-csv" as IconProp} />
                CSV Export
              </Button>
            </Row>
          </Col>
        ) : undefined}
      </Row>
      <Table
        columns={initialColumns}
        dataSource={assetPriceList}
        expandable={{ expandedRowRender }}
        sticky
        scroll={{ x: 400 }}
        showSorterTooltip={false}
        pagination={{ pageSize: currPageSize, hideOnSinglePage: true }}
      />
      <Row justify="end" className="pagination-row">
        <Pagination
          showSizeChanger
          showQuickJumper
          onChange={onPaginationChange}
          current={currentPage}
          pageSize={currPageSize}
          total={assetCount}
        />
      </Row>
    </Content>
  ) : (
    <Content>
      <Layout
        style={{
          paddingTop: "30vh",
          minHeight: "100vh",
        }}
      >
        <Spin size="large" />
      </Layout>
    </Content>
  );
}
