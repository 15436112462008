import { Tabs } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import ComplianceAddressScanList from "./ComplianceAddressScanList";
import ComplianceAddressScan from "./ComplianceAddressScan";

export default function ComplianceAddressScanContainer() {
  const { tabName = "scan" } = useParams();

  const navigate = useNavigate();
  return (
    <Tabs
      defaultActiveKey={tabName}
      type="card"
      onChange={(key) => {
        navigate("/bo/scan-tools/address-scan/" + key);
      }}
      items={[
        {
          label: "Scan",
          key: "scan",
          children: <ComplianceAddressScan />,
        },
        {
          label: "List",
          key: "list",
          children: <ComplianceAddressScanList />,
        },
      ]}
    />
  );
}
