import { UploadOutlined } from "@ant-design/icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Row,
  Tooltip,
  Upload,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import type { RcFile, UploadFile } from "antd/es/upload/interface";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import useCopyToClipboard from "../../../hooks/useCopyToClipboard";
import { DisplayContext } from "../../Context/displayContext";

//walletRiskJSON
export default function ViewRiskRawData({
  riskData: { walletRiskJSON, userVerifiedBy, verifiedDate, comments },
  riskId,
  canComment,
  onSubmitComment,
  canUpload, // Can Upload if the client is not unknown
}: {
  riskData: {
    walletRiskJSON: any;
    userVerifiedBy: any;
    verifiedDate: any;
    comments: any[];
  };
  riskId: any;
  canComment: boolean;
  onSubmitComment: any;
  canUpload: boolean;
}) {
  const displayContext = useContext(DisplayContext);

  const [, copy] = useCopyToClipboard();
  const [savingComments, setSavingComments] = useState<boolean>(false);
  const [newComment, setNewComment] = useState<string>("");
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [displayTimezone, setDisplayTimezone] = useState<string>();
  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
  }, [displayContext, displayTimezone]);

  const formatJson = (obj: any, level: number = 0): JSX.Element => {
    const entries = Object.entries(obj);

    // Separate entries into non-array and array entries
    const nonArrayEntries = entries.filter(
      ([_, value]) => !Array.isArray(value),
    );
    const arrayEntries = entries.filter(([_, value]) => Array.isArray(value));

    // Helper function to format key-value pairs
    const formatEntries = (entries: [string, any][]) =>
      entries.map(([key, value]) => {
        const formattedKey = key.charAt(0).toUpperCase() + key.slice(1); // Capitalize first letter
        const indent = { paddingLeft: `${level * 20}px` }; // Indentation for nested levels

        if (
          typeof value === "object" &&
          value !== null &&
          !Array.isArray(value)
        ) {
          // Recursive handling for nested objects
          return (
            <div key={key} style={{ marginBottom: "8px" }}>
              <div style={indent}>
                <strong>{formattedKey}:</strong>
              </div>
              {formatJson(value, level + 1)}
            </div>
          );
        } else if (Array.isArray(value)) {
          // Check if array contains objects
          const isArrayOfObjects = value.every(
            (item) => typeof item === "object" && item !== null,
          );

          if (isArrayOfObjects) {
            // Handle array of objects with proper prefix and consistent indentation
            return (
              <div key={key} style={{ marginBottom: "8px" }}>
                <div style={indent}>
                  <strong>{formattedKey}:</strong>
                </div>
                <div style={{ paddingLeft: `${(level + 1) * 20}px` }}>
                  {value.map((item, index) => (
                    <div key={index} style={{ marginBottom: "8px" }}>
                      <div>
                        <strong>{index + 1}.</strong>{" "}
                        {formatJson(item, level + 2)}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          } else {
            // Handle simple arrays
            return (
              <div key={key} style={{ marginBottom: "8px" }}>
                <div style={indent}>
                  <strong>{formattedKey}:</strong> [{value.join(", ")}]
                </div>
              </div>
            );
          }
        } else {
          // Handle primitive values
          return (
            <div key={key} style={{ marginBottom: "8px" }}>
              <div style={indent}>
                <strong>{formattedKey}:</strong> {value}
              </div>
            </div>
          );
        }
      });

    return (
      <>
        {formatEntries(nonArrayEntries)}
        {formatEntries(arrayEntries)}
      </>
    );
  };

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  // Prevent auto-upload and add the file to our state.
  const handleBeforeUpload = (file: RcFile): boolean => {
    // Create an UploadFile object that includes the original file object
    const newFile: UploadFile = {
      uid: file.uid,
      name: file.name,
      status: "done",
      originFileObj: file,
    };

    setFileList((prevList) => [...prevList, newFile]);
    return false; // Returning false prevents the automatic upload
  };

  // Remove a file from the list when the user clicks the remove icon
  const handleRemove = (file: UploadFile): boolean => {
    setFileList((prevList) => prevList.filter((f) => f.uid !== file.uid));
    return true;
  };

  const submitComment = useCallback(() => {
    const file = canUpload ? fileList?.[0] : undefined;
    setSavingComments(true);
    if (file && file.originFileObj) {
      // Create a FormData object
      const formData = new FormData();

      // Append the file (buffer) and other form fields
      formData.append("file", file.originFileObj); // The file's buffer
      formData.append("newComment", newComment);
      formData.append("fileName", file.originFileObj.name);
      formData.append("fileType", file.originFileObj.type);
      formData.append("riskId", riskId);
      formData.append("isVerified", isVerified.toString());
      axios({
        method: "Post",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/risk/comment",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      })
        .then(() => {
          toast.success("Comment Addded");
        })
        .catch((err) => {
          toast.error("Failed To Comment");
        })
        .finally(() => {
          setSavingComments(false);
          onSubmitComment();
        });
    } else {
      axios({
        method: "Post",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/risk/comment",
        data: {
          newComment,
          riskId,
          isVerified,
        },
        withCredentials: true,
      })
        .then(() => {
          toast.success("Comment Addded");
        })
        .catch((err) => {
          toast.error("Failed To Comment");
        })
        .finally(() => {
          setSavingComments(false);
          onSubmitComment();
        });
    }
  }, [newComment, isVerified, riskId, onSubmitComment, fileList, canUpload]);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const downloadFile = useCallback((key: string) => {
    setIsDownloading(true);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/risk/file",
      withCredentials: true,
      params: {
        key,
      },
    })
      .then((res) => {
        const url = res.data?.url;
        window.open(url, "_blank");
      })
      .catch((err) => {
        toast.error("Something is wrong");
      })
      .finally(() => {
        setIsDownloading(false);
      });
  }, []);

  return (
    <Card
      title={<b>Risk Data</b>}
      id={"view-risk-raw-data"}
      style={{
        width: "100%",
        marginTop: "2em",
        maxHeight: "80vh",
        overflow: "auto",
      }}
      extra={
        <Button
          onClick={() => {
            copy(walletRiskJSON);
            toast.success("Copied!");
          }}
        >
          <FontAwesomeIcon icon={"fa-regular fa-copy" as IconProp} />
          Raw JSON Data
        </Button>
      }
    >
      <Row>
        <Col span={24}>
          {comments?.length ? (
            <React.Fragment>
              <Row>
                <b style={{ fontSize: "14px" }}>Previous Comments:</b>
              </Row>
              {comments.map((row: any, index: number) => (
                <li
                  key={index}
                  style={{ marginLeft: "8px", marginBottom: "8px" }}
                >
                  <b>
                    {index + 1}. {row.user}(
                    {new Date(row.timestamp)
                      .toLocaleString("en-US", {
                        timeZone: displayTimezone,
                        timeZoneName: "short",
                      })
                      .split(", ")
                      .join(" ")}
                    ):
                  </b>{" "}
                  <span style={{ color: "#19acb3 " }}>{row.comment}</span>{" "}
                  {row.key ? (
                    <Tooltip title={row.fileName}>
                      <Button
                        style={{
                          border: "none",
                          background: "none",
                          boxShadow: "none",
                        }}
                        onClick={() => {
                          downloadFile(row.key);
                        }}
                        loading={isDownloading}
                      >
                        <FontAwesomeIcon
                          icon={"fa-solid fa-download" as IconProp}
                        />
                      </Button>
                    </Tooltip>
                  ) : undefined}
                </li>
              ))}
              {verifiedDate ? (
                <Row>
                  <li>
                    <b style={{ fontSize: "14px" }}>Verified By: </b>
                    <span style={{ color: "#19acb3 " }}>
                      {userVerifiedBy} (
                      {new Date(verifiedDate)
                        .toLocaleString("en-US", {
                          timeZone: displayTimezone,
                          timeZoneName: "short",
                        })
                        .split(", ")
                        .join(" ")}
                      )
                    </span>
                  </li>
                </Row>
              ) : undefined}
              <Divider />
            </React.Fragment>
          ) : undefined}
        </Col>
        <Col span={14}>
          {canComment ? (
            <Form.Item label={<b>Comments</b>}>
              <TextArea
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
              />
            </Form.Item>
          ) : undefined}
          {userVerifiedBy ? undefined : (
            <Form.Item label={<b>Verified</b>}>
              <Checkbox
                disabled={!canComment}
                checked={isVerified}
                onChange={(e) => setIsVerified(e.target.checked)}
              />
            </Form.Item>
          )}
          {isVerified && canComment ? (
            <Form.Item label={<b>Upload File</b>}>
              <Upload
                fileList={fileList}
                beforeUpload={handleBeforeUpload} // Prevent auto-upload
                onRemove={handleRemove} // Allow file removal
                disabled={!canUpload}
                maxCount={1}
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>
          ) : undefined}
        </Col>
      </Row>
      {canComment ? (
        <Row justify={"center"}>
          <Button
            type="primary"
            loading={savingComments}
            disabled={!newComment?.trim() && !fileList?.length}
            onClick={() => {
              submitComment();
            }}
          >
            Submit
          </Button>
        </Row>
      ) : undefined}
      <Divider />
      <div>{formatJson(JSON.parse(walletRiskJSON))}</div>
    </Card>
  );
}
