import { Row } from "antd";
import { Content } from "antd/es/layout/layout";

export default function ClientCustodyReport() {
  return (
    <Content>
      <Row
        justify={"center"}
        style={{
          marginTop: "5em",
        }}
      >
        <h2 style={{ color: "black" }}>Coming Soon...</h2>
      </Row>
    </Content>
  );
}
