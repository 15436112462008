import {
  Row,
  Space,
  Spin,
  Table,
  DatePicker,
  Tooltip,
  Form,
  Col,
  Button,
  Input,
  Select,
} from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import dayjs from "dayjs";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DisplayContext } from "../Context/displayContext";
const { RangePicker } = DatePicker;

export default function ClientCustodyTransactions() {
  const navigate = useNavigate();
  const displayContext = useContext(DisplayContext);

  const CUSTODY_WALLET_TYPE = "walletTypeInCustody";
  const [pagination, setPagination] = useState<any>();
  const [onFinishLoaded, setOnFinishLoaded] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any[]>();
  const [tableLength, setTableLength] = useState<number>(0);
  const [filterForm] = Form.useForm();

  const filterTransactions = useCallback(
    (form?: any, pagination?: any) => {
      setOnFinishLoaded(false);
      axios({
        method: "Post",
        url:
          process.env.REACT_APP_AWS_BACKEND_URL +
          "/util/custody/transactions-with-filter",
        withCredentials: true,
        data: {
          ...form,
          walletType: CUSTODY_WALLET_TYPE,
          offset: pagination?.current || 0,
          pageSize: pagination?.pageSize || 10,
        },
      })
        .then((res) => {
          setTableData(res.data.transactions);
          setTableLength(res.data.total);
          if (!pagination?.current) {
            setPagination({
              current: 1,
              pageSize: pagination?.pageSize || 10,
              total: res.data.total,
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 403) {
            navigate("/login");
          }
        })
        .finally(() => {
          setOnFinishLoaded(true);
        });
    },
    [navigate],
  );
  const initialColumns = [
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      width: 250,
      render: (_: any, { timestamp }: any) => (
        <Space>
          {new Date(timestamp).toLocaleString("en-US", {
            timeZone: displayContext?.displayContext.timezone,
            timeZoneName: "short",
          })}
        </Space>
      ),
    },
    {
      title: "Subwallet Label",
      dataIndex: "subWalletLabel",
      width: 200,
      render: (_: any, { subWallet }: any) => <Space>{subWallet.label}</Space>,
    },
    {
      title: "Wallet Address",
      dataIndex: "walletAddress",
      width: 200,
    },
    {
      title: "Asset Ticker",
      dataIndex: "assetTikcer",
      width: 100,
      render: (_: any, { assetPrice, subWallet }: any) => (
        <Space>
          <Tooltip title={`Price: ${assetPrice}`}>
            {subWallet.asset ? subWallet.asset.ticker : ""}
          </Tooltip>
        </Space>
      ),
    },
    {
      title: "Transaction Hash",
      dataIndex: "hash",
      width: 200,
    },
    {
      title: "Block Number",
      dataIndex: "blockNumber",
      width: 150,
    },
    {
      title: "Quantity",
      dataIndex: "value",
      width: 150,
      align: "right" as const,
      render: (_: any, { value }: any) => (
        <Space>
          {Number(value).toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 8,
          })}
        </Space>
      ),
    },
    {
      title: "Quantity in USD",
      dataIndex: "usdValue",
      width: 150,
      align: "right" as const,
      render: (_: any, { value, assetPrice }: any) => (
        <Space>
          $
          {(Number(value) * Number(assetPrice)).toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Space>
      ),
    },
    {
      title: "Fee",
      dataIndex: "fee",
      width: 150,
      align: "right" as const,
      render: (_: any, { fee }: any) => (
        <Space>
          {Number(fee).toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 8,
          })}
        </Space>
      ),
    },
    {
      title: "Fee Currency",
      dataIndex: "feeCurrency",
      width: 150,
      render: (_: any, { assetFeeCurrency, feeAssetPrice }: any) => (
        <Space>
          <Tooltip title={`Price: ${feeAssetPrice}`}>
            {assetFeeCurrency ? assetFeeCurrency.ticker : ""}
          </Tooltip>
        </Space>
      ),
    },
    {
      title: "Fee in USD",
      dataIndex: "feeUsdValue",
      width: 150,
      align: "right" as const,
      render: (_: any, { fee, feeAssetPrice }: any) => (
        <Tooltip title={Number(fee) * Number(feeAssetPrice)}>
          $
          {(Number(fee) * Number(feeAssetPrice)).toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Tooltip>
      ),
    },
  ];

  useEffect(() => {
    filterTransactions();
  }, [filterTransactions]);

  const onReset = useCallback(() => {
    filterTransactions(undefined, { current: 0, pageSize: 10 });
    filterForm.resetFields();
  }, [filterForm, filterTransactions]);

  const onPaginationChange = useCallback(
    (pagination: { current?: number; pageSize?: number }) => {
      setPagination(pagination);
      filterTransactions(filterForm.getFieldsValue(), pagination);
    },
    [filterForm, filterTransactions],
  );

  const [assetOptions, setAssetOptions] = useState<any[]>();
  const getAssetOptions = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/select-list",
      withCredentials: true,
    })
      .then((res) => {
        const options = [];
        for (const asset of res.data.assetList) {
          options.push({
            label: `${asset.name} (${asset.ticker})`,
            value: asset.id,
          });
        }
        setAssetOptions(options);
      })
      .catch(() => {
        console.log("Unable to fetch the asset list");
      });
  }, []);
  useEffect(() => {
    getAssetOptions();
  }, [getAssetOptions]);
  const disabledDate: RangePickerProps["disabledDate"] = (current: any) => {
    // Can not select days after today and today
    return current > dayjs().endOf("d");
  };

  return (
    <Content id="client-custody">
      <Row className="dcl-filter-row">
        <Col md={{ span: 16 }} sm={{ span: 16 }}>
          <Form
            title="Filter"
            form={filterForm}
            onFinish={filterTransactions}
            className="dcl-toggled-content dcl-toggled-content-filter"
          >
            <Row>
              <Form.Item name="dateRange" className="dcl-filter-item">
                <RangePicker
                  style={{ width: "100%" }}
                  disabledDate={disabledDate}
                  className="dcl-daterange-select"
                />
              </Form.Item>

              <Form.Item
                name="address"
                className="dcl-filter-item"
                normalize={(value) => value.trim()}
              >
                <Input placeholder="Wallet Address" />
              </Form.Item>
              <Form.Item name="assetIds" className="dcl-filter-item">
                <Select
                  options={assetOptions}
                  placeholder="Asset Ticker"
                  className="dcl-asset-select"
                  showSearch
                  mode="multiple"
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
              <Form.Item
                name="txnHash"
                className="dcl-filter-item"
                normalize={(value) => value.trim()}
              >
                <Input placeholder="Transaction Hash" />
              </Form.Item>
            </Row>
            <Row justify="end">
              <Space>
                <b>{tableLength} Transactions</b>
                <Button
                  htmlType="submit"
                  className="ant-btn-primary"
                  loading={!onFinishLoaded}
                >
                  Apply
                </Button>
                <Button onClick={onReset} loading={!onFinishLoaded}>
                  Reset
                </Button>
              </Space>
            </Row>
          </Form>
        </Col>
      </Row>
      {onFinishLoaded ? (
        <Table
          dataSource={tableData}
          sticky
          pagination={pagination}
          onChange={(pagination) => {
            onPaginationChange(pagination);
          }}
          scroll={{ x: 1950 }}
          columns={initialColumns}
        />
      ) : (
        <Row justify={"center"} style={{ padding: "24px" }}>
          <Spin />
        </Row>
      )}
    </Content>
  );
}
