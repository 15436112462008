import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Divider,
  Input,
  Modal,
  Row,
  Space,
  Spin,
  Table,
} from "antd";
import { Content } from "antd/es/layout/layout";
import { Form } from "antd/lib";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import useCopyToClipboard from "../../../hooks/useCopyToClipboard";
import { DisplayContext } from "../../Context/displayContext";

export default function ComplianceTransactionScanList() {
  const displayContext = useContext(DisplayContext);
  const formatArray = (type: string, array: any[], level = 0) => {
    // Helper function to format key-value pairs
    const formatEntries = (entries: any) =>
      entries.map(([key, value]: any) => {
        const formattedKey = (key.charAt(0).toUpperCase() + key.slice(1))
          .replace(/([a-z])([A-Z])/g, "$1 $2")
          .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2"); // Capitalize first letter
        const indent = { paddingLeft: `${level * 20}px` }; // Indentation for nested levels

        if (
          typeof value === "object" &&
          value !== null &&
          !Array.isArray(value)
        ) {
          // Recursive handling for nested objects
          return (
            <div key={key} style={{ marginBottom: "8px" }}>
              <div style={indent}>
                <strong>{formattedKey}:</strong>
              </div>
              {formatArray(type, [value], level + 1)}
            </div>
          );
        } else if (Array.isArray(value)) {
          // Check if array contains objects
          const isArrayOfObjects = value.every(
            (item) => typeof item === "object" && item !== null,
          );

          if (isArrayOfObjects) {
            // Handle array of objects with proper prefix and consistent indentation
            return (
              <div key={key} style={{ marginBottom: "8px" }}>
                <div style={indent}>
                  <strong>{formattedKey}:</strong>
                </div>
                <div style={{ paddingLeft: `${(level + 1) * 20}px` }}>
                  {value.map((item, index) => (
                    <div key={index} style={{ marginBottom: "8px" }}>
                      <div>
                        <strong>{index + 1}.</strong>{" "}
                        {formatArray(type, [item], level + 2)}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          } else {
            // Handle simple arrays
            return (
              <div key={key} style={{ marginBottom: "8px" }}>
                <div style={indent}>
                  <strong>{formattedKey}:</strong> [{value.join(", ")}]
                </div>
              </div>
            );
          }
        } else {
          // Handle primitive values
          return (
            <div key={key} style={{ marginBottom: "8px" }}>
              <div style={indent}>
                <strong>{formattedKey}:</strong> {value || "N/A"}
              </div>
            </div>
          );
        }
      });

    if (!array || array.length === 0) {
      return <div>No {type}s.</div>;
    }

    return (
      <>
        {array.map((alert: any, index: number) => (
          <div key={index} style={{ marginBottom: "16px" }}>
            <strong>
              {type.charAt(0).toUpperCase() + type.slice(1)} {index + 1}:
            </strong>
            <div style={{ paddingLeft: `${(level + 1) * 20}px` }}>
              {formatEntries(Object.entries(alert))}
            </div>
          </div>
        ))}
      </>
    );
  };

  const [filterForm] = Form.useForm();
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [count, setCount] = useState<number>();
  const [tableData, setTableData] = useState<any>();
  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 50,
  });
  const [scanResult, setScanResult] = useState<any>();
  const [, copy] = useCopyToClipboard();
  const fetchData = useCallback((form?: any, pagination?: any) => {
    const offset =
      !pagination || pagination?.current === 1
        ? undefined
        : pagination?.current * pagination?.pageSize;
    setIsFetchingData(true);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/risk",
      params: {
        ...form,
        type: "transaction",
        offset: offset || 0,
        pageSize: pagination?.pageSize || 50,
        excludeAuto: "true",
      },
      withCredentials: true,
    })
      .then((res) => {
        setTableData(res.data.result);
        setCount(res.data.total);
        if (!pagination?.current) {
          setPagination({
            current: 1,
            pageSize: pagination?.pageSize || 50,
            total: res.data.count,
          });
        }
      })
      .finally(() => {
        setIsFetchingData(false);
      });
  }, []);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const onReset = useCallback(() => {
    fetchData();
    filterForm.resetFields();
  }, [filterForm, fetchData]);
  const applyFilter = useCallback(() => {
    const form = filterForm.getFieldsValue();
    fetchData(form, {
      current: 0,
      pageSize: pagination?.pageSize,
    });
    setPagination({ ...pagination, current: 1 });
  }, [fetchData, filterForm, pagination]);

  const onPaginationChange = useCallback(
    (pagination: { current?: number; pageSize?: number }) => {
      setPagination(pagination);
      fetchData(filterForm.getFieldsValue(), pagination);
    },
    [filterForm, fetchData],
  );

  const copyExternalId = (externalId: string) => {
    copy(externalId);
    toast.success(`${externalId} copied to clipboard`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  return (
    <Content id="transaction-scan-list">
      <Row className="dcl-filter-row">
        <Col md={{ span: 16 }} sm={{ span: 16 }}>
          <Form
            form={filterForm}
            className="dcl-toggled-content dcl-toggled-content-filter"
          >
            <Form.Item name="transferReference" className="dcl-filter-item">
              <Input placeholder="Transfer Reference" />
            </Form.Item>
            <Row justify="end">
              <Space>
                <b>Total: {count}</b>
                <Button
                  onClick={applyFilter}
                  className="ant-btn-primary"
                  loading={isFetchingData}
                >
                  Apply
                </Button>
                <Button onClick={onReset} loading={isFetchingData}>
                  Reset
                </Button>
              </Space>
            </Row>
          </Form>
        </Col>
      </Row>
      {isFetchingData ? (
        <Row justify={"center"} style={{ margin: "12px" }}>
          <Spin />
        </Row>
      ) : (
        <ConfigProvider
        //   theme={{
        //     components: {
        //       Button: {
        //         colorText: "#fff",
        //       },
        //     },
        //   }}
        >
          <Table
            dataSource={tableData}
            pagination={pagination}
            sticky
            onChange={(pagination) => {
              onPaginationChange(pagination);
            }}
            rowKey={"id"}
            // scroll={{ x: 1000 }}
            columns={[
              {
                title: "Timestamp",
                dataIndex: "timestamp",
                width: 250,
                render: (_: any, { scanJSON }: any) => (
                  <Space>
                    {scanJSON?.transferDetail?.timestamp
                      ? new Date(
                          scanJSON?.transferDetail?.timestamp,
                        ).toLocaleString("en-US", {
                          timeZone: displayContext?.displayContext.timezone,
                          timeZoneName: "short",
                        })
                      : ""}
                  </Space>
                ),
              },
              {
                title: "Risk",
                dataIndex: "risk",
                hidden: false,
                sorter: false,
                render: (_: any, { scanJSON }: any) => (
                  <Row align={"middle"}>
                    <Button
                      onClick={() => {
                        setScanResult(scanJSON);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={"fa-solid fa-circle-info" as IconProp}
                      />
                    </Button>
                  </Row>
                ),
                width: "70px",
              },
            ]}
          />
        </ConfigProvider>
      )}
      <Modal
        open={scanResult}
        onCancel={() => {
          setScanResult(undefined);
        }}
        onClose={() => {
          setScanResult(undefined);
        }}
        width={"80%"}
      >
        {scanResult ? (
          <Card
            style={{
              width: "100%",
              height: "100%",
              marginTop: "2em",
              marginBottom: "2em",
              maxHeight: "80vh",
              overflow: "auto",
            }}
            title={
              <Row justify="start" align="middle">
                <b>External ID: </b> &nbsp;
                {scanResult?.externalId}
                <Button
                  onClick={() => {
                    copyExternalId(scanResult?.externalId);
                  }}
                  style={{
                    border: "none",
                    background: "none",
                    boxShadow: "none",
                  }}
                >
                  <FontAwesomeIcon icon={"fa-regular fa-copy" as IconProp} />
                </Button>
              </Row>
            }
          >
            <Row gutter={[10, 5]}>
              <Col span={12} key="txn-scan-col-1">
                <Row>
                  <b>Updated At:</b> &nbsp;
                  {scanResult?.transferDetail?.updatedAt}
                </Row>
                <Divider>
                  <b>Alerts</b>
                </Divider>
                {formatArray("alert", scanResult?.alertsDetail?.alerts || [])}
                <Divider>
                  <b>Exposures</b>
                </Divider>

                {scanResult?.exposureDetail?.direct?.name ? (
                  <React.Fragment>
                    <Row>
                      <b>Name: </b> &nbsp;
                      {scanResult?.exposureDetail?.direct?.name}
                    </Row>
                    {scanResult?.exposureDetail?.direct?.categoryId ? (
                      <Row>
                        <b>Category Id: </b>&nbsp;
                        {scanResult?.exposureDetail?.direct?.categoryId}
                      </Row>
                    ) : undefined}
                  </React.Fragment>
                ) : (
                  <Row>No Exposures</Row>
                )}
              </Col>
              <Col span={12} key="txn-scan-col-2">
                <Divider style={{ marginTop: "0px" }}>
                  <b>Summary</b>
                </Divider>
                {Object.entries(scanResult?.transferDetail).map(
                  ([key, value]: any) => (
                    <div key={key} style={{ marginBottom: "8px" }}>
                      <div
                        style={{
                          wordBreak: "break-word",
                        }}
                      >
                        <strong>
                          {(key.charAt(0).toUpperCase() + key.slice(1))
                            .replace(/([a-z])([A-Z])/g, "$1 $2")
                            .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")}
                          :&nbsp;
                        </strong>
                        {value}
                      </div>
                    </div>
                  ),
                )}
              </Col>
            </Row>
          </Card>
        ) : undefined}
      </Modal>
    </Content>
  );
}
