import {
  Button,
  Col,
  ConfigProvider,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Spin,
  Table,
  Tooltip,
} from "antd";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { DisplayContext } from "../../Context/displayContext";
import ViewRiskRawData from "./ViewRiskRawData";

export default function ComplianceAddressScanList() {
  const displayContext = useContext(DisplayContext);
  const [filterForm] = Form.useForm();
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [count, setCount] = useState<number>();
  const [tableData, setTableData] = useState<any>();
  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 50,
  });

  const fetchData = useCallback((form?: any, pagination?: any) => {
    const offset =
      !pagination || pagination?.current === 1
        ? undefined
        : pagination?.current * pagination?.pageSize;
    setIsFetchingData(true);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/risk",
      params: {
        ...form,
        type: "wallet",
        offset: offset || 0,
        pageSize: pagination?.pageSize || 50,
        excludeAuto: "true",
      },
      withCredentials: true,
    })
      .then((res) => {
        setTableData(res.data.res);
        setCount(res.data.count);
        if (!pagination?.current) {
          setPagination({
            current: 1,
            pageSize: pagination?.pageSize || 50,
            total: res.data.count,
          });
        }
      })
      .finally(() => {
        setIsFetchingData(false);
      });
  }, []);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const onReset = useCallback(() => {
    fetchData();
    filterForm.resetFields();
  }, [filterForm, fetchData]);
  const applyFilter = useCallback(() => {
    const form = filterForm.getFieldsValue();
    fetchData(form, {
      current: 0,
      pageSize: pagination?.pageSize,
    });
    setPagination({ ...pagination, current: 1 });
  }, [fetchData, filterForm, pagination]);

  const onPaginationChange = useCallback(
    (pagination: { current?: number; pageSize?: number }) => {
      setPagination(pagination);
      fetchData(filterForm.getFieldsValue(), pagination);
    },
    [filterForm, fetchData],
  );

  const [canComment, setCanComment] = useState<boolean>(false);
  const [canUpload, setCanUpload] = useState<boolean>(false);
  const fetchCanComment = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/risk/can-comment",
      withCredentials: true,
    })
      .then((res) => setCanComment(res.data.canComment))
      .catch((err) => {
        console.error(err);
      });
  }, []);
  useEffect(() => {
    fetchCanComment();
  }, [fetchCanComment]);
  const [showRiskRawModal, setShowRiskRawModal] = useState<boolean>(false);
  const [riskData, setRiskData] = useState<any>();
  const [riskId, setRiskId] = useState<number | undefined>();
  const onModalClose = useCallback(() => {
    setRiskData(undefined);
    setRiskId(undefined);
    setShowRiskRawModal(false);
    setCanUpload(false);
  }, []);
  const onSubmitComment = useCallback(() => {
    onModalClose();
    fetchData(filterForm.getFieldsValue(), pagination);
  }, [fetchData, filterForm, onModalClose, pagination]);
  return (
    <Content id={"address-scan-list"}>
      <Row className="dcl-filter-row">
        <Col md={{ span: 16 }} sm={{ span: 16 }}>
          <Form
            form={filterForm}
            className="dcl-toggled-content dcl-toggled-content-filter"
          >
            <Form.Item name="walletAddress" className="dcl-filter-item">
              <Input placeholder="Wallet Address" />
            </Form.Item>
            <Row justify="end">
              <Space>
                <b>Total: {count}</b>
                <Button
                  onClick={applyFilter}
                  className="ant-btn-primary"
                  loading={isFetchingData}
                >
                  Apply
                </Button>
                <Button onClick={onReset} loading={isFetchingData}>
                  Reset
                </Button>
              </Space>
            </Row>
          </Form>
        </Col>
      </Row>
      {isFetchingData ? (
        <Row justify={"center"} style={{ margin: "12px" }}>
          <Spin />
        </Row>
      ) : (
        <ConfigProvider
          theme={{
            components: {
              Button: {
                colorText: "#fff",
              },
            },
          }}
        >
          <Table
            dataSource={tableData}
            pagination={pagination}
            sticky
            onChange={(pagination) => {
              onPaginationChange(pagination);
            }}
            rowKey={"id"}
            scroll={{ x: 1000 }}
            columns={[
              {
                title: "Timestamp",
                dataIndex: "timestamp",
                width: 250,
                render: (_: any, { timestamp }: any) => (
                  <Space>
                    {new Date(timestamp).toLocaleString("en-US", {
                      timeZone: displayContext?.displayContext.timezone,
                      timeZoneName: "short",
                    })}
                  </Space>
                ),
              },
              {
                title: "Wallet",
                dataIndex: "wallet",
                width: 250,
              },
              {
                title: "Risk",
                dataIndex: "risk",
                hidden: false,
                sorter: false,
                render: (_: any, risk: any) => (
                  <Row align={"middle"}>
                    <Tooltip
                      title={
                        <React.Fragment>
                          <Row>
                            Risk: {risk?.serviceRiskMap?.riskLevel?.name}
                          </Row>
                          <Row>
                            Date:{" "}
                            {new Date(risk?.timestamp).toLocaleString("en-us", {
                              timeZone: displayContext?.displayContext.timezone,
                              timeZoneName: "short",
                            })}
                          </Row>
                        </React.Fragment>
                      }
                    >
                      <Button
                        className={`dcl-risk-button dcl-risk-${risk?.serviceRiskMap?.riskLevel?.name}`}
                        shape="circle"
                        style={{
                          fontWeight: "bold",
                        }}
                        onClick={() => {
                          setShowRiskRawModal(true);
                          setRiskData({...risk, userVerifiedBy: risk.userIdVerifiedBy});
                          setRiskId(risk?.id);
                          setCanUpload(risk?.canUpload);
                        }}
                      >
                        {risk?.serviceRiskMap?.riskLevel?.name?.[0]}
                      </Button>
                    </Tooltip>
                  </Row>
                ),
                width: "70px",
              },
            ]}
          />
        </ConfigProvider>
      )}
      <Modal
        open={showRiskRawModal}
        footer={[]}
        closable={true}
        onCancel={() => {
          onModalClose();
        }}
        onClose={() => {
          onModalClose();
        }}
        width={"80%"}
        destroyOnClose
      >
        <ViewRiskRawData
          riskData={riskData}
          riskId={riskId}
          canComment={canComment}
          onSubmitComment={onSubmitComment}
          canUpload={canUpload}
        />
      </Modal>
    </Content>
  );
}
