import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Table,
} from "antd";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DisplayContext } from "../../Context/displayContext";

export default function ComplianceAddressScan() {
  const location = useLocation();
  const navigate = useNavigate();
  const [displayTimezone, setDisplayTimezone] = useState<string>();
  const displayContext = useContext(DisplayContext);
  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
  }, [displayContext, displayTimezone]);

  const [addressInput, setAddressInput] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [isWalletLoading, setIsWalletLoading] = useState<boolean>(false);
  const [scanResult, setScanResult] = useState<any>();
  const [scanResultComponent, setScanResultComponent] =
    useState<any>(undefined);
  const [isWalletListFetched, setIsWalletListFetched] =
    useState<boolean>(false);
  const [walletList, setWalletList] = useState<any[]>([]);
  const [isAddressScaned, setIsAddressScaned] = useState<boolean>(false);
  const [addressInUse, setAddressInUse] = useState<string>("");
  const [walletCreationForm] = Form.useForm();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const fetchWalletExist = useCallback(
    (walletAddress: string) => {
      setIsWalletLoading(true);
      setIsWalletListFetched(false);
      walletCreationForm?.resetFields();
      axios({
        method: "Post",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/wallets",
        withCredentials: true,
        data: {
          address: walletAddress,
        },
      })
        .then((res) => {
          setWalletList(res.data.wallets);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setIsWalletLoading(false);
          setIsWalletListFetched(true);
        });
    },
    [walletCreationForm],
  );

  useEffect(() => {
    if (location?.state?.addressInUse) {
      setAddressInput(location.state?.addressInUse);
    } else {
      setAddressInUse("");
    }
  }, [location]);

  // Compliance Data in DB
  const [isFetchingSavedCompliaceData, setIsFetchingSavedComplianceData] =
    useState<boolean>(false);
  const [savedComplianceData, setSavedComplianceDate] = useState<any[]>([]);
  const [previousDateOptions, setPreviousDateOptions] = useState<any[]>([]);
  const [selectedDate, setSelectedDate] = useState<any>();
  const [selectedDateComplianceDetail, setSelectedDateComplianceDetail] =
    useState<any>();
  const [previousResultComponent, setPreviousResultComponent] = useState<any>();
  const fetchWalletComplianceData = useCallback(() => {
    if (!walletList?.length) return;
    setIsFetchingSavedComplianceData(true);
    const walletIds = walletList.map((row) => row.id);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/risk",
      params: {
        walletIds: JSON.stringify(walletIds),
        type: "wallet",
      },
      withCredentials: true,
    })
      .then((res) => {
        setSavedComplianceDate(res.data?.res);
        if (res.data?.count) {
          const dateOptions = res.data?.res.map((row: any) => ({
            label: new Date(row.timestamp)
              .toLocaleString("en-US", {
                timeZone: displayTimezone,
                timeZoneName: "short",
              })
              .split(", ")
              .join(" "),
            value: row.id,
            key: row.id,
            walletRiskJSON: row.walletRiskJSON,
          }));
          setPreviousDateOptions(dateOptions);
          setSelectedDate(dateOptions[0].value);
          setSelectedDateComplianceDetail(res.data?.res?.[0]?.walletRiskJSON);
        } else {
          setPreviousDateOptions([]);
          setSelectedDate(undefined);
          setSelectedDateComplianceDetail(undefined);
        }
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      })
      .finally(() => {
        setIsFetchingSavedComplianceData(false);
      });
  }, [walletList, displayTimezone, navigate]);

  const renderPreviousResult = useCallback(
    (result: any, selectedDateComplianceDetail: any) => {
      if (!result || !result.length) setPreviousResultComponent(undefined);
      else {
        const selectedDateComplianceDetailJSON = JSON.parse(
          selectedDateComplianceDetail,
        );
        setPreviousResultComponent(
          <React.Fragment>
            <Row
              align="middle"
              justify="space-around"
              style={{ marginTop: "2em" }}
            >
              <Col span={22}>
                <Divider orientation="left" orientationMargin={0}>
                  Previous Results - (Scanned {previousDateOptions?.length} time
                  {previousDateOptions?.length === 1 ? undefined : "s"})
                </Divider>
              </Col>
            </Row>
            <Row align="middle" justify="space-around">
              <Col span={22}>
                <Form.Item label={<b>Date</b>} style={{ marginBottom: "12px" }}>
                  <Select
                    options={previousDateOptions}
                    value={selectedDate}
                    style={{
                      maxWidth: "500px",
                    }}
                    onChange={(e, option) => {
                      setSelectedDate(e);
                      setSelectedDateComplianceDetail(option?.walletRiskJSON);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle" justify="space-around">
              <Col span={22}>
                <Form.Item
                  label={<b>Address</b>}
                  style={{ marginBottom: "12px" }}
                >
                  {selectedDateComplianceDetailJSON?.address}
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle" justify="space-around">
              <Col span={22}>
                <Form.Item
                  label={<b>Address Type</b>}
                  style={{ marginBottom: "12px" }}
                >
                  {selectedDateComplianceDetailJSON?.addressType}
                </Form.Item>
              </Col>
            </Row>
            {selectedDateComplianceDetailJSON?.cluster ? (
              <Row align="middle" justify="space-around">
                <Col span={22}>
                  <Form.Item
                    label={<b>Venue Category</b>}
                    style={{ marginBottom: "12px" }}
                  >
                    {selectedDateComplianceDetailJSON?.cluster?.category}
                  </Form.Item>
                </Col>
              </Row>
            ) : undefined}
            {selectedDateComplianceDetailJSON?.cluster ? (
              <Row align="middle" justify="space-around">
                <Col span={22}>
                  <Form.Item
                    label={<b>Venue Name</b>}
                    style={{ marginBottom: "12px" }}
                  >
                    {selectedDateComplianceDetailJSON?.cluster?.name}
                  </Form.Item>
                </Col>
              </Row>
            ) : undefined}

            <Row align="middle" justify="space-around">
              <Col span={22} style={{ marginBottom: "24px" }}>
                <Row align="middle">
                  <b>Risk</b>:&nbsp;
                  <Space
                    className={`dcl-risk-${selectedDateComplianceDetailJSON?.risk?.toLocaleLowerCase()}`}
                  >
                    {selectedDateComplianceDetailJSON?.risk}
                  </Space>
                </Row>
              </Col>
            </Row>
            <Row justify="space-around">
              <Col span={22}>
                <Collapse
                  defaultActiveKey={1}
                  items={[
                    {
                      key: 1,
                      label: (
                        <b>
                          Exposures (
                          {selectedDateComplianceDetailJSON?.exposures?.length})
                        </b>
                      ),
                      children: (
                        <Table
                          rowKey={(record: any) =>
                            `${record?.category}-${record?.exposureType}`
                          }
                          columns={[
                            {
                              title: "Category",
                              dataIndex: "category",
                            },
                            {
                              title: "Exposure Type",
                              dataIndex: "exposureType",
                            },
                            {
                              title: "Direction",
                              dataIndex: "direction",
                            },
                            {
                              title: "Value",
                              dataIndex: "value",
                              align: "right",
                            },
                          ]}
                          dataSource={
                            selectedDateComplianceDetailJSON?.exposures
                          }
                        />
                      ),
                    },
                    {
                      key: 2,
                      label: (
                        <b>
                          Triggers (
                          {selectedDateComplianceDetailJSON?.triggers?.length})
                        </b>
                      ),
                      children: (
                        <Table
                          rowKey={(record: any) => `${record?.category}`}
                          columns={[
                            {
                              title: "Category",
                              dataIndex: "category",
                            },
                            {
                              title: "Message",
                              dataIndex: "message",
                            },
                            {
                              title: "Risk",
                              dataIndex: "ruleTriggered",
                              render: (_: any, { ruleTriggered }: any) => (
                                <React.Fragment>
                                  {ruleTriggered?.risk}
                                </React.Fragment>
                              ),
                            },
                            {
                              title: "Percentage",
                              dataIndex: "percentage",
                              align: "right",
                            },
                          ]}
                          dataSource={
                            selectedDateComplianceDetailJSON?.triggers
                          }
                        />
                      ),
                    },
                  ]}
                />
              </Col>
            </Row>
          </React.Fragment>,
        );
      }
    },
    [previousDateOptions, selectedDate],
  );

  useEffect(() => {
    renderPreviousResult(savedComplianceData, selectedDateComplianceDetail);
  }, [renderPreviousResult, savedComplianceData, selectedDateComplianceDetail]);

  useEffect(() => {
    fetchWalletComplianceData();
  }, [fetchWalletComplianceData]);

  const handleScan = useCallback(() => {
    setIsLoading(true);
    setIsAddressScaned(false);
    setAddressInUse(addressInput?.trim());
    fetchWalletExist(addressInput?.trim());
    axios({
      method: "Post",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL + "/util/compliance/scan/wallet",
      data: {
        address: addressInput?.trim(),
        type: "wallet",
      },
      withCredentials: true,
    })
      .then((res) => {
        setScanResult(res.data);
        setIsAddressScaned(true);
      })
      .catch((err) => {
        toast.error("Error occur!");
        if (err.response.status === 403) {
          navigate("/login");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [fetchWalletExist, addressInput, navigate]);

  const saveComplianceData = useCallback(() => {
    if (walletCreationForm) {
      walletCreationForm
        .validateFields()
        .then(() => {
          setIsSaving(true);
          axios({
            method: "Post",
            url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/risk",
            data: {
              walletAddress: addressInUse,
              walletIds: walletList?.map((row) => row.id),
              complianceJSON: scanResult,
              ...walletCreationForm.getFieldsValue(),
            },
            withCredentials: true,
          })
            .then(() => {
              toast.success(
                walletList?.length
                  ? "Compliance Data Saved"
                  : "Wallet Created and Compliance Data Saved",
              );
              fetchWalletExist(addressInUse);
            })
            .catch((err) => {
              toast.error("Error occur!");
              if (err.response.status === 403) {
                navigate("/login");
              }
            })
            .finally(() => {
              setIsSaving(false);
            });
        })
        .catch((err) => {
          toast.warning("Please provide wallet information");
        });
    }
  }, [
    walletCreationForm,
    walletList,
    scanResult,
    navigate,
    fetchWalletExist,
    addressInUse,
  ]);

  const renderResult = useCallback(
    (result: any) => {
      if (!result) setScanResultComponent(undefined);
      else
        setScanResultComponent(
          <React.Fragment>
            <Row justify={"space-around"} style={{ marginTop: "2em" }}>
              <Button
                onClick={() => {
                  saveComplianceData();
                }}
                loading={isSaving}
                type="primary"
              >
                Save Scanning Result
              </Button>
            </Row>
            <Row align="middle" justify="space-around">
              <Col span={22}>
                <Divider orientation="left" orientationMargin={0}>
                  Scanning Details
                </Divider>
              </Col>
            </Row>

            <Row align="middle" justify="space-around">
              <Col span={22}>
                <Form.Item
                  label={<b>Address</b>}
                  style={{ marginBottom: "12px" }}
                >
                  {result?.address}
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle" justify="space-around">
              <Col span={22}>
                <Form.Item
                  label={<b>Address Type</b>}
                  style={{ marginBottom: "12px" }}
                >
                  {result?.addressType}
                </Form.Item>
              </Col>
            </Row>
            {result?.cluster ? (
              <Row align="middle" justify="space-around">
                <Col span={22}>
                  <Form.Item
                    label={<b>Venue Category</b>}
                    style={{ marginBottom: "12px" }}
                  >
                    {result?.cluster?.category}
                  </Form.Item>
                </Col>
              </Row>
            ) : undefined}
            {result?.cluster ? (
              <Row align="middle" justify="space-around">
                <Col span={22}>
                  <Form.Item
                    label={<b>Venue Name</b>}
                    style={{ marginBottom: "12px" }}
                  >
                    {result?.cluster?.name}
                  </Form.Item>
                </Col>
              </Row>
            ) : undefined}

            <Row align="middle" justify="space-around">
              <Col span={22} style={{ marginBottom: "24px" }}>
                <Row align="middle">
                  <b>Risk</b>:&nbsp;
                  <Space
                    className={`dcl-risk-${result?.risk?.toLocaleLowerCase()}`}
                  >
                    {result?.risk}
                  </Space>
                </Row>
              </Col>
            </Row>
            <Row justify="space-around">
              <Col span={22}>
                <Collapse
                  defaultActiveKey={1}
                  items={[
                    {
                      key: 1,
                      label: <b>Exposures ({result?.exposures?.length})</b>,
                      children: (
                        <Table
                          rowKey={(record: any) =>
                            `${record?.category}-${record?.exposureType}`
                          }
                          columns={[
                            {
                              title: "Category",
                              dataIndex: "category",
                            },
                            {
                              title: "Exposure Type",
                              dataIndex: "exposureType",
                            },
                            {
                              title: "Direction",
                              dataIndex: "direction",
                            },
                            {
                              title: "Value",
                              dataIndex: "value",
                              align: "right",
                            },
                          ]}
                          dataSource={result?.exposures}
                        />
                      ),
                    },
                    {
                      key: 2,
                      label: <b>Triggers ({result?.triggers?.length})</b>,
                      children: (
                        <Table
                          rowKey={(record: any) => `${record?.category}`}
                          columns={[
                            {
                              title: "Category",
                              dataIndex: "category",
                            },
                            {
                              title: "Message",
                              dataIndex: "message",
                            },
                            {
                              title: "Risk",
                              dataIndex: "ruleTriggered",
                              render: (_: any, { ruleTriggered }: any) => (
                                <React.Fragment>
                                  {ruleTriggered?.risk}
                                </React.Fragment>
                              ),
                            },
                            {
                              title: "Percentage",
                              dataIndex: "percentage",
                              align: "right",
                            },
                          ]}
                          dataSource={result?.triggers}
                        />
                      ),
                    },
                  ]}
                />
              </Col>
            </Row>
          </React.Fragment>,
        );
    },
    [saveComplianceData, isSaving],
  );
  useEffect(() => {
    renderResult(scanResult);
  }, [renderResult, scanResult]);

  // Creating wallet utils
  const [clientOptions, setClientOptions] = useState<any[]>();
  const [assetNetworkList, setAssetNetworkList] = useState<any>();
  const getClientOptions = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/client-list",
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.clients) {
          res.data.clients.forEach(
            (client: any) =>
              (client.label = `${client.label} (${client.deltecAccount})`),
          );
        }
        setClientOptions(res.data.clients);
      })
      .catch(() => {
        console.log("Unable to fetch the role list");
      });
  }, []);
  const fetchAssetApiUrlUtils = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/asset-url-utils",
      withCredentials: true,
    }).then((res) => {
      setAssetNetworkList(res.data.networks);
    });
  }, []);
  useEffect(() => {
    getClientOptions();
    fetchAssetApiUrlUtils();
  }, [getClientOptions, fetchAssetApiUrlUtils]);

  return (
    <Content id={"compliance-address-scan"}>
      <Row align="middle" justify={"center"} style={{ marginTop: "12px" }}>
        <Col xxl={12} md={18} sm={24}>
          <Input
            placeholder="Wallet Address"
            value={addressInput}
            onChange={(e) => setAddressInput(e.target.value)}
            style={{ padding: "8px", borderRadius: "4px" }}
          />
        </Col>
        <Col span={24} style={{ textAlign: "center", marginTop: "10px" }}>
          <Button
            type="primary"
            onClick={handleScan}
            disabled={!addressInput}
            style={{ width: "100px" }}
          >
            {isLoading ? <Spin size="small" /> : "Scan"}
          </Button>
        </Col>
      </Row>
      {isWalletLoading ? (
        <Row justify={"center"} style={{ marginTop: "1em" }}>
          <Spin />
        </Row>
      ) : isWalletListFetched ? (
        <Row justify="space-around">
          <Col span={22}>
            <Divider orientation="left" orientationMargin={0}>
              Wallets
            </Divider>
            {walletList?.length ? (
              <Table
                rowKey={"id"}
                dataSource={walletList}
                columns={[
                  {
                    title: "Client",
                    dataIndex: "clientName",
                    render: (_: any, { clientName, clientId }: any) => (
                      <Link
                        to={"/bo/client/info/" + encodeURIComponent(clientId)}
                        state={{
                          from: window.location.pathname,
                          addressInUse: addressInUse,
                        }}
                      >
                        {clientName}
                      </Link>
                    ),
                  },
                  {
                    title: "Label",
                    dataIndex: "label",
                  },
                  {
                    title: "Is Active",
                    dataIndex: "isActive",
                    render: (_: any, { isActive }: any) => (
                      <Checkbox checked={isActive} disabled />
                    ),
                  },
                  {
                    title: "Is Client Personal",
                    dataIndex: "isClientPersonal",
                    render: (_: any, { isClientPersonal }: any) => (
                      <Checkbox checked={isClientPersonal} disabled />
                    ),
                  },
                ]}
                pagination={{
                  hideOnSinglePage: true,
                }}
              />
            ) : (
              <Card>
                <Row>
                  <Col span={22}>
                    <Alert
                      style={{
                        marginBottom: "1em",
                      }}
                      message={
                        <React.Fragment>
                          <p style={{ color: "black" }}>
                            <b>
                              This address hasn't been recorded yet. Please
                              provide following information before saving.
                            </b>
                          </p>
                          <p style={{ color: "black" }}>
                            <b>
                              A new wallet will be created and linked to the
                              client.
                            </b>
                          </p>
                        </React.Fragment>
                      }
                      type="warning"
                      showIcon
                    />
                  </Col>

                  <Col md={24} sm={24} lg={12} xl={8} xxl={8}>
                    <Form form={walletCreationForm}>
                      <Form.Item
                        label={<b>Client</b>}
                        name="clientId"
                        rules={[
                          {
                            required: true,
                            message: "Please select client!",
                          },
                        ]}
                      >
                        <Select
                          options={clientOptions}
                          showSearch
                          allowClear
                          filterOption={(input, option: any) => {
                            return option.label
                              .toLowerCase()
                              .includes(input.toLowerCase());
                          }}
                          placeholder="Client"
                        />
                      </Form.Item>
                      <Form.Item
                        label={<b>Network</b>}
                        name="assetNetworkId"
                        rules={[
                          {
                            required: true,
                            message: "Please select network!",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          allowClear
                          filterOption={(input, option: any) =>
                            option.label
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={assetNetworkList}
                          placeholder="Network"
                        />
                      </Form.Item>
                      <Form.Item
                        label={<b>Label</b>}
                        name="label"
                        rules={[
                          {
                            required: true,
                            message: "Please input label!",
                          },
                        ]}
                      >
                        <Input placeholder="Wallet label" />
                      </Form.Item>
                      <Form.Item
                        label={<b>Client Personal Address</b>}
                        name="isClientPersonal"
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </Card>
            )}
          </Col>
        </Row>
      ) : undefined}
      {isAddressScaned ? (
        scanResultComponent
      ) : isLoading ? (
        <Row justify={"center"} style={{ marginTop: "1em" }}>
          <Spin />
        </Row>
      ) : undefined}

      {isFetchingSavedCompliaceData ? (
        <Row justify={"center"} style={{ marginTop: "1em" }}>
          <Spin />
        </Row>
      ) : isLoading ? undefined : (
        previousResultComponent
      )}
    </Content>
  );
}
