import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Col,
  Layout,
  Row,
  Space,
  Spin,
  Switch,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import { DisplayContext } from "../Context/displayContext";
const { Text } = Typography;

function MyCompanyProfile() {
  const displayContext = useContext(DisplayContext);
  const [displayTimezone, setDisplayTimezone] = useState<string>();
  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
  }, [displayContext, displayTimezone]);

  const location = useLocation();
  const navigate = useNavigate();
  const [, copy] = useCopyToClipboard();
  const { clientId } = useParams();
  const [client, setClient] = useState<any>({});

  const [assetPrices, setAssetPrices] = useState<any>();
  const [canGetWallet, setCanGetWallet] = useState<boolean>(false);
  const [custodyWallets, setCustodyWallets] = useState<any[]>([]);
  const [stakingWallets, setStakingWallets] = useState<any[]>([]);
  const [isLoadingClient, setIsLoadingClient] = useState<boolean>(true);
  const [isLoadingWallet, setIsLoadingWallet] = useState<boolean>(true);

  const [totalAuc, setTotalAuc] = useState<number>(0);
  const [totalStakingSum, setTotalStakingSum] = useState<number>(0);
  const [personalWallets, setPersonalWallets] = useState<any[]>([]);

  const fetchWallets = useCallback(() => {
    setIsLoadingWallet(true);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/client-wallets",
      params: {
        clientId: clientId ? clientId : undefined,
      },
      withCredentials: true,
    })
      .then((res) => {
        setAssetPrices(res.data.assetPrices);
        let allSubWallets: any[] = [];
        setPersonalWallets(
          res.data.wallets?.filter((row: any) => row.isClientPersonal),
        );
        if (res.data.wallets) {
          res.data.wallets.forEach((t: any) => {
            const subWallets = t.subWallets.map((s: any) => {
              return {
                ...s,
                walletAddress: t.walletAddress,
                walletId: t.id,
                clientAccountNumber: t.clientAccount
                  ? t.clientAccount.accountNumber
                  : "",
              };
            });
            allSubWallets = allSubWallets.concat(subWallets);
          });
        }
        const c =
          allSubWallets && allSubWallets.length
            ? allSubWallets.filter(
                (t: any) =>
                  t.walletType &&
                  t.walletType.walletCategory &&
                  t.walletType.walletCategory.name === "Custody",
              )
            : [];

        const s =
          allSubWallets && allSubWallets.length
            ? allSubWallets.filter(
                (t: any) =>
                  t.walletType &&
                  t.walletType.walletCategory &&
                  t.walletType.walletCategory.name === "Staking",
              )
            : [];
        setCustodyWallets(c);
        setStakingWallets(s);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoadingWallet(false);
      });
  }, [clientId]);

  const fetchData = useCallback((params = {}) => {
    setIsLoadingClient(true);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/client",
      params: {
        encodedClientId: clientId ? clientId : "",
      },
      withCredentials: true,
    })
      .then((res) => {
        setClient(res.data.client);
        setCanGetWallet(res.data.canGetWallet);
        if (res.data.canGetWallet) {
          fetchWallets();
        }
      })
      .catch(() => {
        toast.error("Unable to fetch client info", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate("/bo/clients");
      })
      .finally(() => {
        setIsLoadingClient(false);
      });
    // eslint-disable-next-line
  }, []);

  const cardGridLeft = {
    width: "35%",
    textAlign: "left",
  } as const;
  const cardGridRight = {
    width: "65%",
    textAlign: "left",
  } as const;

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    let aucSum = 0;
    let stakingSum = 0;
    if (custodyWallets && assetPrices) {
      for (const wallet of custodyWallets) {
        if (!wallet.isActive || !wallet.isReport) continue;
        if (wallet.displayBalance && assetPrices[wallet.assetId])
          aucSum += wallet.displayBalance * assetPrices[wallet.assetId];
      }
    }
    if (stakingWallets && assetPrices) {
      for (const wallet of stakingWallets) {
        if (!wallet.isActive || !wallet.isReport) continue;
        if (wallet.displayBalance && assetPrices[wallet.assetId])
          stakingSum += wallet.displayBalance * assetPrices[wallet.assetId];
      }
    }

    setTotalStakingSum(stakingSum);
    setTotalAuc(aucSum);
  }, [custodyWallets, stakingWallets, assetPrices]);
  return (
    <React.Fragment>
      {!isLoadingClient ? (
        <Content id="client-info">
          <Row>
            <Col span={4}>
              <Button
                onClick={() => {
                  if (location.state) {
                    navigate(location.state.from, { state: location.state });
                  } else {
                    navigate("/bo/clients");
                  }
                }}
              >
                <FontAwesomeIcon
                  icon={"fa-solid fa-circle-chevron-left" as IconProp}
                />
              </Button>
            </Col>
            <Col span={16} style={{ textAlign: "center" }}>
              <h2>{client.clientName}</h2>
            </Col>
          </Row>

          <Row style={{ padding: "10px  0" }}>
            <Col lg={8} md={24} sm={24} xs={24}>
              <Card
                title={
                  <Row>
                    <Col span={8}>Details</Col>
                  </Row>
                }
                bordered={false}
                style={{
                  margin: "10px",
                  borderRadius: "10px",
                }}
              >
                <Card.Grid style={cardGridLeft}>
                  <b>Email:</b>
                </Card.Grid>
                <Card.Grid style={cardGridRight}>
                  <Text
                    style={{ width: "100%" }}
                    ellipsis={{ tooltip: client.email }}
                  >
                    {client.email}
                  </Text>
                </Card.Grid>
                <Card.Grid style={cardGridLeft}>
                  <b>Is Active:</b>
                </Card.Grid>
                <Card.Grid style={cardGridRight}>
                  {client.clientName ? (
                    <Switch defaultChecked={client.isActive} disabled />
                  ) : (
                    <></>
                  )}
                </Card.Grid>
              </Card>
            </Col>
            <Col lg={8} md={24} sm={24} xs={24}>
              <Card
                title={
                  <Row>
                    <Col span={8}>Accounts</Col>
                  </Row>
                }
                bordered={false}
                style={{
                  margin: "10px",
                  borderRadius: "10px",
                }}
              >
                <Card.Grid style={cardGridLeft}>
                  <b>Deltec Account:</b>
                </Card.Grid>
                <Card.Grid style={cardGridRight}>
                  {client.deltecAccount ? client.deltecAccount : "N/A"}
                </Card.Grid>
                <Card.Grid style={cardGridLeft}>
                  <b>Delchain Account:</b>
                </Card.Grid>
                <Card.Grid style={cardGridRight}>
                  {client.delchainAccount ? client.delchainAccount : "N/A"}
                </Card.Grid>
              </Card>
            </Col>
            <Col lg={8} md={24} sm={24} xs={24}>
              <Card
                title={
                  <Row>
                    <Col span={8}>Address</Col>
                  </Row>
                }
                bordered={false}
                style={{
                  margin: "10px",
                  borderRadius: "10px",
                }}
              >
                <Card.Grid style={cardGridLeft}>
                  <b>Address Line 1:</b>
                </Card.Grid>
                <Card.Grid style={cardGridRight}>
                  {client.address ? client.address.addressLine1 : ""}
                </Card.Grid>
                <Card.Grid style={cardGridLeft}>
                  <b>Address Line 2:</b>
                </Card.Grid>
                <Card.Grid style={cardGridRight}>
                  {client.address ? client.address.addressLine2 : ""}
                </Card.Grid>
                <Card.Grid style={cardGridLeft}>
                  <b>Postcode:</b>
                </Card.Grid>
                <Card.Grid style={cardGridRight}>
                  {client.address ? client.address.postcode : ""}
                </Card.Grid>
                <Card.Grid style={cardGridLeft}>
                  <b>City:</b>
                </Card.Grid>
                <Card.Grid style={cardGridRight}>
                  {client.address ? client.address.city : ""}
                </Card.Grid>
                <Card.Grid style={cardGridLeft}>
                  <b>State:</b>
                </Card.Grid>
                <Card.Grid style={cardGridRight}>
                  {client.address ? client.address.state : ""}
                </Card.Grid>
                <Card.Grid style={cardGridLeft}>
                  <b>Country:</b>
                </Card.Grid>
                <Card.Grid style={cardGridRight}>
                  {client.address && client.address.country
                    ? client.address.country.nicename
                    : ""}
                </Card.Grid>
              </Card>
            </Col>

            {canGetWallet ? (
              isLoadingWallet ? (
                <Col span={24}>
                  <Row justify={"center"}>
                    <Spin size="large" />
                  </Row>
                </Col>
              ) : (
                <React.Fragment>
                  {custodyWallets && custodyWallets.length ? (
                    <Col lg={24} md={24}>
                      <Card
                        title={
                          <Row>
                            <Col span={8}>
                              Custody Wallets ({custodyWallets?.length}{" "}
                              {personalWallets?.length === 1
                                ? "Wallet"
                                : "Wallets"}
                              )
                            </Col>
                          </Row>
                        }
                        bordered={false}
                        style={{
                          margin: "10px",
                          borderRadius: "10px",
                        }}
                        extra={
                          <Space>
                            <b>AUC: </b>
                            {totalAuc.toLocaleString("en-us", {
                              style: "currency",
                              currency: "USD",
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 5,
                            })}
                          </Space>
                        }
                      >
                        <Table
                          scroll={{ x: 1000 }}
                          size="small"
                          dataSource={custodyWallets}
                          columns={[
                            {
                              title: "Asset",
                              dataIndex: "asset",
                              width: "5%",
                              render: (_: any, { asset }: any) => (
                                <Space>{asset?.ticker}</Space>
                              ),
                            },
                            {
                              title: "Address",
                              dataIndex: "Address",
                              width: "5%",
                              render: (
                                _: any,
                                { walletAddress, walletId }: any,
                              ) => (
                                <Tooltip title={walletAddress}>
                                  {walletAddress && walletAddress.length > 4
                                    ? walletAddress.substring(0, 4) +
                                      "..." +
                                      walletAddress.substr(-4, 4)
                                    : walletAddress
                                    ? walletAddress
                                    : ""}
                                  {walletAddress ? (
                                    <Button
                                      style={{
                                        border: "none",
                                        margin: "0",
                                      }}
                                      onClick={() => copy(walletAddress)}
                                      size={"small"}
                                    >
                                      <FontAwesomeIcon
                                        icon={"fa-regular fa-copy" as IconProp}
                                      />
                                    </Button>
                                  ) : (
                                    <></>
                                  )}
                                </Tooltip>
                              ),
                            },
                            {
                              title: "Label",
                              dataIndex: "label",
                              width: "5%",
                            },
                            {
                              title: "Client Account",
                              dataIndex: "clientAccountNumber",
                              width: "8%",
                              render: (
                                _: any,
                                { clientAccountNumber }: any,
                              ) => (
                                <Tooltip title={clientAccountNumber}>
                                  {clientAccountNumber}
                                </Tooltip>
                              ),
                            },
                            {
                              title: "Is Active",
                              dataIndex: "isActive",
                              width: "5%",
                              render: (_: any, { isActive }: any) => (
                                <Switch disabled checked={isActive} />
                              ),
                            },
                            {
                              title: "In Report",
                              dataIndex: "isReport",
                              width: "5%",
                              render: (_: any, { isReport }: any) => (
                                <Switch disabled checked={isReport} />
                              ),
                            },
                            {
                              title: "Is Placeholder",
                              dataIndex: "isPlaceholder",
                              width: "5%",
                              render: (_: any, { isPlaceholder }: any) => (
                                <Switch disabled checked={isPlaceholder} />
                              ),
                            },
                            {
                              title: "Quantity",
                              dataIndex: "displayBalance",
                              width: "12.5%",
                              align: "right",

                              render: (_: any, { displayBalance }: any) => (
                                <Tooltip
                                  title={
                                    displayBalance ? displayBalance : "0.00"
                                  }
                                >
                                  {displayBalance
                                    ? displayBalance.toLocaleString("en-us", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 8,
                                      })
                                    : "0.00"}
                                </Tooltip>
                              ),
                            },
                            {
                              title: "Price",
                              dataIndex: "assetId",
                              width: "12.5%",
                              align: "right",

                              render: (_: any, { assetId }: any) => (
                                <Tooltip
                                  title={
                                    assetPrices &&
                                    assetId &&
                                    assetPrices[assetId]
                                      ? `$${assetPrices[assetId].toLocaleString(
                                          "en-us",
                                        )}`
                                      : `$${Number(0).toLocaleString("en-us")}`
                                  }
                                >
                                  $
                                  {assetPrices &&
                                  assetId &&
                                  assetPrices[assetId]
                                    ? assetPrices[assetId].toLocaleString(
                                        "en-us",
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        },
                                      )
                                    : Number(0).toLocaleString("en-us", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                </Tooltip>
                              ),
                            },
                            {
                              title: "Value in USD",
                              dataIndex: "assetValue",
                              width: "12.5%",
                              align: "right",
                              render: (
                                _: any,
                                { displayBalance, assetId }: any,
                              ) => (
                                <Tooltip
                                  title={
                                    displayBalance && assetPrices && assetId
                                      ? `$${(
                                          assetPrices[assetId] * displayBalance
                                        ).toLocaleString("en-us")}`
                                      : `$${Number(0).toLocaleString("en-us")}`
                                  }
                                >
                                  $
                                  {displayBalance && assetId
                                    ? (
                                        assetPrices[assetId] * displayBalance
                                      ).toLocaleString("en-us", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })
                                    : Number(0).toLocaleString("en-us", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                </Tooltip>
                              ),
                            },
                          ]}
                          pagination={{
                            showSizeChanger: true,
                            pageSizeOptions: [10, 50, 100],
                            showQuickJumper: true,
                          }}
                        />
                      </Card>
                    </Col>
                  ) : (
                    <Col lg={24} md={24}>
                      <Card
                        title={
                          <Row>
                            <Col span={8}>
                              Custody Wallets ({custodyWallets?.length}{" "}
                              {personalWallets?.length === 1
                                ? "Wallet"
                                : "Wallets"}
                              )
                            </Col>
                          </Row>
                        }
                        bordered={false}
                        style={{
                          margin: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <Card.Grid
                          style={{
                            width: "100%",
                            minHeight: "100px",
                            textAlign: "center",
                            justifyContent: "center",
                          }}
                        >
                          <b>No wallets</b>
                        </Card.Grid>
                      </Card>
                    </Col>
                  )}

                  {stakingWallets && stakingWallets.length ? (
                    <Col lg={24} md={24}>
                      <Card
                        title={
                          <Row>
                            <Col span={8}>
                              Staking Wallets({stakingWallets?.length}{" "}
                              {personalWallets?.length === 1
                                ? "Wallet"
                                : "Wallets"}
                              )
                            </Col>
                          </Row>
                        }
                        bordered={false}
                        style={{
                          margin: "10px",
                          borderRadius: "10px",
                        }}
                        extra={
                          <Space>
                            <b>AUM: </b>
                            {totalStakingSum.toLocaleString("en-us", {
                              style: "currency",
                              currency: "USD",
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Space>
                        }
                      >
                        <Table
                          scroll={{ x: 1000 }}
                          size="small"
                          dataSource={stakingWallets}
                          columns={[
                            {
                              title: "Asset",
                              dataIndex: "asset",
                              width: "5%",
                              render: (_: any, { asset }: any) => (
                                <Space>{asset?.ticker}</Space>
                              ),
                            },
                            {
                              title: "Address",
                              dataIndex: "Address",
                              width: "5%",
                              render: (
                                _: any,
                                { walletAddress, walletId }: any,
                              ) => (
                                <Tooltip title={walletAddress}>
                                  {walletAddress && walletAddress.length > 4
                                    ? walletAddress.substring(0, 4) +
                                      "..." +
                                      walletAddress.substr(-4, 4)
                                    : walletAddress
                                    ? walletAddress
                                    : ""}
                                  {walletAddress ? (
                                    <Button
                                      style={{
                                        border: "none",
                                        margin: "0",
                                      }}
                                      onClick={() => copy(walletAddress)}
                                      size={"small"}
                                    >
                                      <FontAwesomeIcon
                                        icon={"fa-regular fa-copy" as IconProp}
                                      />
                                    </Button>
                                  ) : (
                                    <></>
                                  )}
                                </Tooltip>
                              ),
                            },
                            {
                              title: "Label",
                              dataIndex: "label",
                              width: "5%",
                            },
                            {
                              title: "Client Account",
                              dataIndex: "clientAccountNumber",
                              width: "8%",
                              render: (
                                _: any,
                                { clientAccountNumber }: any,
                              ) => (
                                <Tooltip title={clientAccountNumber}>
                                  {clientAccountNumber}
                                </Tooltip>
                              ),
                            },
                            {
                              title: "Is Active",
                              dataIndex: "isActive",
                              width: "5%",
                              render: (_: any, { isActive }: any) => (
                                <Switch disabled checked={isActive} />
                              ),
                            },
                            {
                              title: "In Report",
                              dataIndex: "isReport",
                              width: "5%",
                              render: (_: any, { isReport }: any) => (
                                <Switch disabled checked={isReport} />
                              ),
                            },
                            {
                              title: "Is Placeholder",
                              dataIndex: "isPlaceholder",
                              width: "5%",
                              render: (_: any, { isPlaceholder }: any) => (
                                <Switch disabled checked={isPlaceholder} />
                              ),
                            },
                            {
                              title: "Quantity",
                              dataIndex: "displayBalance",
                              width: "12.5%",
                              align: "right",

                              render: (_: any, { displayBalance }: any) => (
                                <Tooltip
                                  title={
                                    displayBalance ? displayBalance : "0.00"
                                  }
                                >
                                  {displayBalance
                                    ? displayBalance.toLocaleString("en-us", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 8,
                                      })
                                    : "0.00"}
                                </Tooltip>
                              ),
                            },
                            {
                              title: "Price",
                              dataIndex: "assetId",
                              width: "12.5%",
                              align: "right",

                              render: (_: any, { assetId }: any) => (
                                <Tooltip
                                  title={
                                    assetPrices &&
                                    assetId &&
                                    assetPrices[assetId]
                                      ? `$${assetPrices[assetId].toLocaleString(
                                          "en-us",
                                        )}`
                                      : `$${Number(0).toLocaleString("en-us")}`
                                  }
                                >
                                  $
                                  {assetPrices &&
                                  assetId &&
                                  assetPrices[assetId]
                                    ? assetPrices[assetId].toLocaleString(
                                        "en-us",
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        },
                                      )
                                    : Number(0).toLocaleString("en-us", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                </Tooltip>
                              ),
                            },
                            {
                              title: "Value in USD",
                              dataIndex: "assetValue",
                              width: "12.5%",
                              align: "right",
                              render: (
                                _: any,
                                { displayBalance, assetId }: any,
                              ) => (
                                <Tooltip
                                  title={
                                    displayBalance && assetPrices && assetId
                                      ? `$${(
                                          assetPrices[assetId] * displayBalance
                                        ).toLocaleString("en-us")}`
                                      : `$${Number(0).toLocaleString("en-us")}`
                                  }
                                >
                                  $
                                  {displayBalance && assetId
                                    ? (
                                        assetPrices[assetId] * displayBalance
                                      ).toLocaleString("en-us", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })
                                    : Number(0).toLocaleString("en-us", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                </Tooltip>
                              ),
                            },
                          ]}
                          pagination={{
                            showSizeChanger: true,
                            pageSizeOptions: [10, 50, 100],
                            showQuickJumper: true,
                          }}
                        />
                      </Card>
                    </Col>
                  ) : (
                    <Col lg={24} md={24}>
                      <Card
                        title={
                          <Row>
                            <Col span={8}>
                              Staking Wallets ({stakingWallets?.length}{" "}
                              {personalWallets?.length === 1
                                ? "Wallet"
                                : "Wallets"}
                              )
                            </Col>
                          </Row>
                        }
                        bordered={false}
                        style={{
                          margin: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <Card.Grid
                          style={{
                            width: "100%",
                            minHeight: "100px",
                            textAlign: "center",
                            justifyContent: "center",
                          }}
                        >
                          <b>No wallets</b>
                        </Card.Grid>
                      </Card>
                    </Col>
                  )}
                  {personalWallets?.length ? (
                    <Col lg={24} md={24}>
                      <Card
                        title={
                          <Row>
                            <Col span={8}>
                              Whitelist Addresses ({personalWallets?.length}{" "}
                              {personalWallets?.length === 1
                                ? "Wallet"
                                : "Wallets"}
                              )
                            </Col>
                          </Row>
                        }
                        bordered={false}
                        style={{
                          margin: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <Table
                          scroll={{ x: 1000 }}
                          size="small"
                          dataSource={personalWallets}
                          columns={[
                            {
                              title: "Address",
                              dataIndex: "walletAddress",
                              width: "40%",
                              ellipsis: true,
                              render: (_: any, { walletAddress, id }: any) => (
                                <Tooltip title={walletAddress}>
                                  {walletAddress}
                                  {walletAddress ? (
                                    <Button
                                      style={{
                                        border: "none",
                                        margin: "0",
                                      }}
                                      onClick={() => copy(walletAddress)}
                                      size={"small"}
                                    >
                                      <FontAwesomeIcon
                                        icon={"fa-regular fa-copy" as IconProp}
                                      />
                                    </Button>
                                  ) : (
                                    <></>
                                  )}
                                </Tooltip>
                              ),
                            },
                            {
                              title: "Risk",
                              dataIndex: "walletRisk",
                              width: "15%",
                              ellipsis: true,
                              render: (_: any, { walletRisk }: any) => (
                                <React.Fragment>
                                  {walletRisk ? (
                                    <Tooltip
                                      title={
                                        <React.Fragment>
                                          <Row>
                                            Risk: {walletRisk?.riskName}
                                          </Row>
                                          <Row>
                                            Date:{" "}
                                            {new Date(
                                              walletRisk?.timestamp,
                                            ).toLocaleString("en-us", {
                                              timeZone: displayTimezone,
                                              timeZoneName: "short",
                                            })}
                                          </Row>
                                        </React.Fragment>
                                      }
                                    >
                                      <i
                                        className={`dcl-risk-info-lg dcl-risk-${walletRisk?.riskName}`}
                                      >
                                        {walletRisk?.riskName}
                                      </i>
                                    </Tooltip>
                                  ) : undefined}
                                </React.Fragment>
                              ),
                            },
                            {
                              title: "Label",
                              dataIndex: "walletRisk",
                              width: "30%",
                              ellipsis: true,
                            },
                          ]}
                          pagination={{
                            showSizeChanger: true,
                            pageSizeOptions: [10, 50, 100],
                            showQuickJumper: true,
                          }}
                        />
                      </Card>
                    </Col>
                  ) : (
                    <Col lg={24} md={24}>
                      <Card
                        title={
                          <Row>
                            <Col span={8}>
                              Whitelist Addresses ({personalWallets?.length}{" "}
                              {personalWallets?.length === 1
                                ? "Wallet"
                                : "Wallets"}
                              )
                            </Col>
                          </Row>
                        }
                        bordered={false}
                        style={{
                          margin: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <Card.Grid
                          style={{
                            width: "100%",
                            minHeight: "100px",
                            textAlign: "center",
                            justifyContent: "center",
                          }}
                        >
                          <b>No wallets</b>
                        </Card.Grid>
                      </Card>
                    </Col>
                  )}
                </React.Fragment>
              )
            ) : (
              <></>
            )}
          </Row>
        </Content>
      ) : (
        <Content>
          <Layout
            style={{
              paddingTop: "30vh",
              minHeight: "100vh",
            }}
          >
            <Spin size="large" />
          </Layout>
        </Content>
      )}
    </React.Fragment>
  );
}
export default MyCompanyProfile;
