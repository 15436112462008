import { Tabs } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import ComplianceTransactionScan from "./ComplianceTransactionScan";
import ComplianceTransactionScanList from "./ComplianceTransactionScanList";

export default function ComplianceTransactionScanContainer() {
  const { tabName = "scan" } = useParams();

  const navigate = useNavigate();
  return (
    <Tabs
      defaultActiveKey={tabName}
      type="card"
      onChange={(key) => {
        navigate("/bo/scan-tools/transaction-scan/" + key);
      }}
      items={[
        {
          label: "Scan",
          key: "scan",
          children: <ComplianceTransactionScan />,
        },
        {
          label: "List",
          key: "list",
          children: <ComplianceTransactionScanList />,
        },
      ]}
    />
  );
}
